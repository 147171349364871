<template>
    <b-card title="Odprta naročila">
        <h4>Skupaj odprti m² v naročilih: {{this.groupedOrders.materials.reduce((a, b) => a + b.total_area, 0).toFixed(2)}}</h4>
        <h4 class="mt-2">Odprti m² po materialu:</h4>
        <apexchart type="bar" :series="series" :options="options" :categories="categories"></apexchart>
    </b-card>
</template>

<script>

    import VueApexCharts from 'vue-apexcharts'

    export default {
        components: {
            apexchart: VueApexCharts
        },
        data() {
            return {
                groupedOrders: []
            }
        },
        methods: {
            loadData() {
                const thisIns = this
                this.$http.get('/api/v1/user/orders_grouped/')
                    .then(function(response) {
                        thisIns.groupedOrders = response.data
                        thisIns.groupedOrders.materials = thisIns.groupedOrders.materials.sort(function(a, b) {
                            return a.total_area - b.total_area
                        }).reverse()
                        thisIns.groupedOrders.materials.forEach(item => (item.total_area = item.total_area / 1000000))
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            }
        },
        computed: {
            series() {
                const series = [{name: 'orders', data: []}]
                try {
                    this.groupedOrders.materials.forEach(function(order) {
                        series[0].data.push(order.total_area)
                    })
                    return series
                } catch (e) {
                    return series
                }
            },
            categories() {
                const categories = []
                try {
                    this.groupedOrders.materials.forEach(function(order) {
                        categories.push(order.title)
                    })
                    return categories
                } catch (e) {
                    return categories
                }
            },
            options() {
                return {
                    chart: {
                        id: 'materials'
                    },
                    xaxis: {
                        categories: this.categories,
                        labels: {
                            maxWidth: 480,
                            // eslint-disable-next-line no-unused-vars
                            formatter(value, timestamp, opts) {
                                return (`${value}m²`)
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            maxWidth: 600
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true
                        }
                    }
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style>

</style>
